import React from "react"
import { InlineWidget } from "react-calendly"
import "../styles/democalender.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/getfreedemo.scss"
import { Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  useTranslation,
  I18nextContext,
  Link,
} from "gatsby-plugin-react-i18next"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL

const Freedemo = () => {
  const { language } = React.useContext(I18nextContext);
  const context = React.useContext(I18nextContext);
  let e_id = 0;
  let c_id = 0;

  if (typeof window !== 'undefined') {
    // code that uses localStorage here
    let employee_id = localStorage.getItem('e_id');
    e_id = employee_id == null ? 0 : parseInt(employee_id);
    let campaign_id = localStorage.getItem('c_id');
    c_id = campaign_id == null ? 0 : parseInt(campaign_id);
  }

  // const getCallendly = () => {
  //   let url = ""
  //   if (language === "en") {
  //     url = `https://calendly.com/logipulse/60min/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else if (language === "fr") {
  //     url = `https://calendly.com/logipulse/demo-logipulse/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else if (language === "es") {
  //     url = `https://calendly.com/logipulse/demostracion-de-logipulse/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else if (language === "de") {
  //     url = `https://calendly.com/logipulse/logipulse-demo/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else if (language === "nl") {
  //     url = `https://calendly.com/logipulse/logipulse-demo-1/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else if (language === "pt") {
  //     url = `https://calendly.com/logipulse/demonstracao-do-logipulse/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else if (language === "it") {
  //     url = `https://calendly.com/logipulse/logipulse-demo-2/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }
  //   else {
  //     url = `https://calendly.com/logipulse/60min/?utm_source=${e_id}&utm_campaign=${c_id}`
  //   }

  //   return url;
  // }

  const getCallendly = () => {
    let url = ""
    if (language === "en") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else if (language === "fr") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else if (language === "es") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else if (language === "de") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else if (language === "nl") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else if (language === "pt") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else if (language === "it") {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }
    else {
      url = `https://outlook-sdf.office.com/bookwithme/user/618abdd9c7094022aa83ba15ebc58b20%40stellosys.com/meetingtype/35dd6b41-1873-4cbb-9432-d505ccb0e867?anonymous&isanonymous=true`
    }

    return url;
  }

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/free-demo/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/free-demo/`
  const canonicalUrl = buildCanonicalUrl()
  const callendly = getCallendly();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl()

  const { t } = useTranslation()

  const pageSettings = {
    primaryColor: "0498e0",
    textColor: "000000",
    backgroundColor: "ffffff",
    button: "0498e0",
    hideLandingPageDetails: false,
    hideEventTypeDetails: false,
  }

  return (
    <main>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `<!-- Calendly inline widget begin -->
            
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
            <!-- Calendly inline widget end -->`,
          }}
        />
        <title>{t("Logipulse Support - Logistics Support & Services")}</title>
        <Link rel="canonical" href={canonicalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
      </Helmet>
      <div className="getfreedemo-page-div" lang={context.language}>
        <div className="container">
          <div className="row">
            {/* <div class="col-sm-1" ><Link to="/"><Photo src="LogiPulse-Logo-H48.svg" className="photos-sizes" alt="" /></Link></div> */}
            <div className="col-sm-11">
              {/* <a className="BackButton_Style">Back</a> */}
              <Trans>
                <InlineWidget
                  lang={context.language}
                  url={callendly}
                  // url="https://calendly.com/logipulse/demo-logipulse"
                  pageSettings={pageSettings}
                />
              </Trans>
            </div>

          </div>

        </div>
      </div>
    </main>
  )
}

export default Freedemo

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
